<template>
  <div class="netdoc">
    <template v-if="device">
      <b-row>
        <b-col>
          <h1 class="my-4">
            {{ device.fqdn }}
            <small class="text-muted">
              {{ $t('views.nd.netdoc.device') }}
              <NETVSIcon icon="device" />
            </small>
          </h1>
        </b-col>
        <b-col>
          <div class="float-right" style="margin-top: 1.5rem">
            <b-button variant="outline-secondary" @click="showEVLog()" class="ml-1">
              {{ $t('system.event_log') }}
              <NETVSIcon icon="evlog"></NETVSIcon>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <p style="font-size: 1.5em" class="mt-n4">
        {{ device.sysdescr }}
      </p>

      <b-row class="mt-n3">
        <b-col cols="3">
          <ComponentLocation :room_name="device_location.room.number" :bldg_name="device_location.building.number"/>
          <div v-if="device_records">
            <p v-for="ip of device_records" v-bind:key="ip.target_ipaddr" class="mt-n3">
              <span v-if="ip.target_ipaddr">{{ip.target_ipaddr}}</span>
            </p>
          </div>
        </b-col>
        <b-col cols="3">
          <div v-if="device_location.building">
            <div style="float: left;">
              <NETVSIcon icon="geo_address" class="pr-2"/>
            </div>
            <div style="float: left;">
              {{ device_location.building.name }}
              <br>
              {{ device_location.building.street }}
              <br>
              {{ device_location.building.postal_code }}
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <KITMap v-if="can_show_map" :center="device_location.building.geo_location" :zoom="17" style="height: 30vh; width: 100%;">
            <template v-slot:map_content>
              <l-marker :lat-lng="latLng(device_location.building.geo_location.lat, device_location.building.geo_location.lng)"
                        :icon="icon"></l-marker>
            </template>
          </KITMap>
        </b-col>
      </b-row>
      <!-- TODO: Device Ip, room(s), buidling(s), valid, use_vlan -->
      <!-- TODO: compound, parser, zugriffsgruppe, description, RB, mail an oe betreuer -->
      <!---Device an FQDN joinen-->
      <b-card class="shadow mb-4" no-body>
        <b-card-header v-b-toggle:device-attr-list-collapse>
          <h5 class="d-inline">{{ $tc('views.nd.netdoc.device_attr_list', Object.values(device_attr_list).length) }}</h5>
          <NETVSIcon class="collapse-icon" icon="collapse"></NETVSIcon>
        </b-card-header>
        <b-card-body body-class="p-0">
          <b-collapse id="device-attr-list-collapse" :visible="true">
            <PaginatorTable striped :items="Object.values(device_attr_list)" :fields="['ot_attr_def_key_word', 'value']">
              <template v-slot:cell(value)="data">
                <template v-if="data.item.data_type === 'boolean'">
                  <CheckMark :state="data.item.value"></CheckMark>
                </template>
                <template v-else>
                  <p>{{ data.item.value }}</p>
                </template>
              </template>
              <template v-slot:cell(ot_attr_def_key_word)="data">
                <p>
                  <code>{{ data.item.ot_attr_def_key_word }}</code>
                  <br>
                  <!--<span v-if="$i18n.locale === 'de'">{{ device_attr_def_list[data.item.ot_attr_def_key_word].de.value }}</span>
                  <span v-else>{{ device_attr_def_list[data.item.ot_attr_def_key_word].en.value }}</span>-->
                  <span>{{ $t_netdb(device_attr_def_list[data.item.ot_attr_def_key_word])}}</span>
                </p>
              </template>
            </PaginatorTable>
          </b-collapse>
        </b-card-body>
      </b-card>
      <!-- TODO table for modules of device -->
      <b-card class="shadow mb-4" no-body>
        <b-card-header v-b-toggle:ports-collapse>
          <h5 class="d-inline">{{ $tc('views.nd.netdoc.l_port', Object.values(local_l_ports).length) }}</h5>
          <NETVSIcon class="collapse-icon" icon="collapse"></NETVSIcon>
        </b-card-header>
        <b-card-body body-class="p-0">
          <b-collapse id="ports-collapse" :visible="true">
            <!-- TODO: egress vlans, ip interfaces; headline; sorting -->
            <PaginatorTable striped :items="Object.values(local_l_ports)" :fields="['name', 'adm_state_description', 'priority', 'lag', 'remote_p_port', 'vlan_name', 'vlan_id', 'ip_interface', 'egress', 'description']">
              <template v-slot:cell(remote_p_port)="data">
                <template v-if="data.item.remote_p_port_gfk && all_p_ports[data.item.remote_p_port_gfk]">
                  <RouterLink :to="'/netdoc/modules/' + all_p_ports[data.item.local_p_port_gfk].module_gfk + '/p_port/' + all_p_ports[data.item.local_p_port_gfk].gpk">
                    {{ all_p_ports[data.item.local_p_port_gfk].name }}
                  </RouterLink>
                  <NETVSIcon icon="navigate" />
                  <RouterLink class="ml-1" :to="'/netdoc/modules/' + all_p_ports[data.item.remote_p_port_gfk].module_gfk + '/p_port/' + all_p_ports[data.item.remote_p_port_gfk].gpk">
                    {{ all_p_ports[data.item.remote_p_port_gfk].mdl_fq_name }}::{{ all_p_ports[data.item.remote_p_port_gfk].name }}
                  </RouterLink>
                  ({{ all_p_ports[data.item.remote_p_port_gfk].mdl_type }})
                </template>
              </template>
              <template v-slot:cell(vlan_name)="data">
                <template v-if="data.item.ingress_vlan_gfk && vlans[data.item.ingress_vlan_gfk]">
                  <RouterLink :to="'/netdoc/bcds/' + vlans[data.item.ingress_vlan_gfk].bcd">
                    {{ vlans[data.item.ingress_vlan_gfk].name }}
                  </RouterLink>
                </template>
              </template>
              <template v-slot:cell(vlan_id)="data">
                <template v-if="data.item.ingress_vlan_gfk && vlans[data.item.ingress_vlan_gfk]">
                  <RouterLink :to="'/netdoc/bcds/' + vlans[data.item.ingress_vlan_gfk].bcd">
                    {{ vlans[data.item.ingress_vlan_gfk].id }}
                  </RouterLink>
                </template>
              </template>
              <template v-slot:cell(egress)="data">
                <template v-if="egress_vlan_by_l_port[data.item.gpk]?.length === 1">
                  {{vlans[egress_vlan_by_l_port[data.item.gpk][0].vlan_gfk].name}} ({{vlans[egress_vlan_by_l_port[data.item.gpk][0].vlan_gfk].id}})
                </template>
                <EgressList v-else :port="data.item" :egress="vlan_egress_list" :vlans="egress_vlan_by_l_port[data.item.gpk]?.map(egress => vlans[egress.vlan_gfk]).sort((a, b) => a.id > b.id)" />
              </template>
            </PaginatorTable>
          </b-collapse>
        </b-card-body>
      </b-card>
    </template>
    <template v-else>
      <Loading :data="null"></Loading>
    </template>
    <EVLogViewer v-if="ev_device_gpk" modal_id="device-evlog"
                 :title="$t('system.event_log') + ' ' + device.fqdn"
                 refobj_id_field="gpk"
                 :refobj_id_value="ev_device_gpk" ref_obj_fq="nd.device"></EVLogViewer>
  </div>
</template>

<script>

import NETVSIcon from '@/icons/NETVSIcon.vue'
import Loading from '@/components/Loading.vue'
import EVLogViewer from '@/components/EVLogViewer.vue'
import netdocService from '@/api-services/netdoc.service'
import apiutil from '@/util/apiutil'
import PaginatorTable from '@/components/PaginatorTable.vue'
import EgressList from '@/components/EgressList.vue'
import CheckMark from '@/components/CheckMark.vue'
import ComponentLocation from '@/components/nd/ComponentLocation.vue'
import KITMap from '@/components/KITMap.vue'
import {LMarker} from 'vue2-leaflet'// LTileLayer, LMarker } from 'vue2-leaflet';
import {icon, latLng} from 'leaflet'
export default {
  name: 'NetdocDevice',
  components: { KITMap, ComponentLocation, CheckMark, EgressList, PaginatorTable, EVLogViewer, NETVSIcon, Loading, LMarker },
  data() {
    return {
      device_fq_name: undefined,
      device: undefined,

      ev_device_gpk: undefined,

      vlans: {},
      remote_p_ports: {},
      local_l_ports: {},
      all_p_ports: {},
      device_attr_list: [],
      device_attr_def_list: {},
      egress_vlan_by_l_port: {},
      vlan_egress_list: {},
      device_location: {},
      device_records: [],

      icon: icon({iconUrl: '/location.png', iconSize: [32, 42], iconAnchor: [16, 42]})
    }
  },
  methods: {
    latLng,
    showEVLog() {
      this.ev_device_gpk = this.device.gpk
      this.$root.$nextTick(() => {
        this.$root.$emit('bv::show::modal', 'device-evlog')
      })
    },
    async fetchData() {
      this.device = undefined
      this.device_fq_name = this.$route.params.fq_name
      this.vlans = {}
      this.remote_p_ports = {}
      this.local_l_ports = {}
      this.all_p_ports = {}
      this.device_attr_list = []
      this.egress_vlan_by_l_port = {}

      const result = await netdocService.load_device_from_fqdn(this.$store.state, this.device_fq_name)
      this.device = result.data.device_list[0]

      this.$store.commit('setNavigationRefreshHandle', { gpk: this.device.gpk, objType: 'nd.device' })

      this.vlans = Object.assign({},
        apiutil.dict_by_value_of_array(result.data.vlan_list, 'gpk'),
        apiutil.dict_by_value_of_array(result.data.egress_vlan_list, 'gpk')
      )

      this.vlan_egress_list = {}
      for (const vlan of result.data.vlan_egress_list) {
        if (!this.vlan_egress_list[vlan.l_port_gfk]) {
          this.vlan_egress_list[vlan.l_port_gfk] = {}
        }
        this.vlan_egress_list[vlan.l_port_gfk][vlan.vlan_gfk] = vlan
      }

      this.remote_p_ports = apiutil.dict_by_value_of_array(result.data.remote_p_port_list, 'gpk')
      this.local_p_ports = apiutil.dict_by_value_of_array(result.data.local_p_port_list, 'gpk')
      this.all_p_ports = Object.assign({},
        this.local_p_ports,
        this.remote_p_ports,
        apiutil.dict_by_value_of_array(result.data.int_remote_p_port_list, 'gpk')
      )

      this.local_l_ports = apiutil.dict_by_value_of_array(result.data.l_port_list, 'gpk')

      for (const l2p of result.data.l2p_port_list) {
        this.local_l_ports[l2p.l_port_gfk].local_p_port_gfk = l2p.p_port_gfk
        this.local_l_ports[l2p.l_port_gfk].remote_p_port_gfk = this.all_p_ports[this.local_p_ports[l2p.p_port_gfk].dest_connected_gfk].internal_connected_gfk
      }
      for (const attribute of result.data.device_attr_def_list) {
        this.device_attr_def_list[attribute.key_word] = attribute.description
      }
      this.device_attr_list = result.data.device_attr_list

      this.egress_vlan_by_l_port = apiutil.dict_of_lists_by_value_of_array(result.data.vlan_egress_list, 'l_port_gfk')
      // Device Location stuff (ip adresses, buildings,...)
      this.device_records = result.data.record_list
      this.device_location.room = result.data.room_list[0]
      this.device_location.building = result.data.building_list[0]
      // this.module = result.data.module_list[0]
      // this.local_p_ports = apiutil.dict_by_value_of_array(result.data.local_p_port_list, 'gpk')
      // this.remote_p_ports = apiutil.dict_by_value_of_array(result.data.remote_p_port_list, 'gpk')
      // this.int_remote_p_ports = apiutil.dict_by_value_of_array(result.data.int_remote_p_port_list, 'gpk')
      // this.all_remote_p_ports = Object.assign({}, this.remote_p_ports, this.int_remote_p_ports, apiutil.dict_by_value_of_array(result.data.int_local_p_port_list, 'gpk'))
      //
      // // magic to resolve destination p_ports
      // for (const p of Object.values(this.local_p_ports)) {
      //   let search_id
      //   if (p.connection_id_nodes[0] === p.connection_id_nodes[1]) {
      //     // p is the start of a path => we'll search the end
      //     search_id = p.connection_id_nodes[2]
      //   } else if (p.connection_id_nodes[0] === p.connection_id_nodes[2]) {
      //     // p is the end of a path => we'll search the start
      //     search_id = p.connection_id_nodes[1]
      //   } else {
      //     // p is neither start nor end
      //     // this means this port is a port looking from the module in the direction of the end
      //     // of the connection path. ==> dest_connected_gfk is ensured to contain one port of the destination module
      //     // this can than be resolved by the internal connection.
      //     this.p_port_ends_map[p.gpk] = this.all_remote_p_ports[p.dest_connected_gfk].internal_connected_gfk
      //   }
      //   if (p.connection_id_nodes[0] === search_id) {
      //     // special case: port is only connected to itself => not connected.
      //     // No need to further search a target.
      //     continue
      //   }
      //   for (const remote_port of Object.values(this.remote_p_ports)) {
      //     if (remote_port.connection_id_nodes[0] === search_id) {
      //       if (remote_port.internal_connected_gfk) {
      //         this.p_port_ends_map[p.internal_connected_gfk] = remote_port.internal_connected_gfk
      //       } else {
      //         this.p_port_ends_map[p.internal_connected_gfk] = remote_port.gpk
      //       }
      //       break
      //     }
      //   }
      // }
    }
  },
  async created() {
    await this.fetchData()
  },
  computed: {
    can_show_map: function () {
      if (this.device_location.building && this.device_location.building.geo_location) {
        if (this.device_location.building.geo_location.lat && this.device_location.building.geo_location.lng) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    $route() {
      this.fetchData()
    }
  }
}
</script>

<style scoped>
.collapse-icon {
  display: inline;
  vertical-align: center;
  float: right;

  transition: transform;
  transition-duration: 250ms;
}

.not-collapsed > .collapse-icon {
  transform: rotate(-180deg);
}

</style>
